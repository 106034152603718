import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CircularProgress, Backdrop, Grid } from "@material-ui/core";
import { Connection, PublicKey, Transaction, clusterApiUrl, SystemProgram } from "@solana/web3.js";

import CustomTextButton from "components/CustomButton/CustomTextButton";
import {
    closeModal,
    purchaseNft,
    toggleLoading,
    handlePhantomConnectWithoutOpen,
    restorePhantomData,
    updateLoadingText,
    resetLoadingText,
} from "store/actions";
import { SOLANA_CLUSTER } from "helpers";
import { toast } from "react-toastify";

const NETWORK = clusterApiUrl(SOLANA_CLUSTER);

const BuyNowModalContent = ({ nftId, nftDetails }) => {
    const dispatch = useDispatch();
    const connection = new Connection(NETWORK);

    const { loading, isConnected, publicKey, loadingText } = useSelector(
        ({ loading, phantom: { isConnected, connectedPubKey, loadingText } }) => ({
            loading: loading,
            isConnected: isConnected,
            publicKey: connectedPubKey,
            loadingText,
        })
    );

    useEffect(() => {
        dispatch(restorePhantomData());
    }, []);

    const createTransferTransaction = async () => {
        if (publicKey === "") {
            toast.info("Connect to wallet first");
            return null;
        }

        if (publicKey == nftDetails?.owner_id?.wallet_id?.wallet_id) {
            toast.info("Please connect to another wallet. Currently, the connected wallet holds this NFT");
            return null;
        }

        let transaction = new Transaction().add(
            SystemProgram.transfer({
                fromPubkey: new PublicKey(publicKey),
                toPubkey: new PublicKey(nftDetails?.owner_id?.wallet_id?.wallet_id),
                lamports: nftDetails?.selling_price,
            })
        );
        transaction.feePayer = new PublicKey(publicKey);

        const anyTransaction = transaction;
        anyTransaction.recentBlockhash = (await connection.getRecentBlockhash()).blockhash;
        return transaction;
    };

    const handleBuyNow = async () => {
        dispatch(toggleLoading(true));
        dispatch(updateLoadingText("We're initializing Transaction. Do not click back or refresh this page."));

        const transaction = await createTransferTransaction();
        if (transaction) {
            try {
                let userBalance = await connection.getBalance(new PublicKey(publicKey));
                if (userBalance < nftDetails?.selling_price) {
                    throw Error("Insufficient Balance");
                }

                let signed = await window.solana.signTransaction(transaction);
                dispatch(updateLoadingText("We're verifying the transaction. Do not click back or refresh this page."));
                console.log("Got signature, submitting transaction");
                let signature = await connection.sendRawTransaction(signed.serialize());
                console.log("Submitted transaction " + signature + ", awaiting confirmation");
                await connection.confirmTransaction(signature);
                dispatch(
                    updateLoadingText("We're transferring NFT to your wallet. Do not click back or refresh this page.")
                );
                dispatch(purchaseNft({ destWalletId: publicKey, nftId: nftId, signature: signature }));
            } catch (err) {
                dispatch(toggleLoading(false));
                dispatch(resetLoadingText());
                toast.error(err.message);
            }
        } else {
            dispatch(toggleLoading(false));
        }
    };

    return (
        <>
            <Backdrop open={loading} style={{ zIndex: 999 }}>
                <Grid container>
                    <Grid
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        <CircularProgress color="secondary" />
                    </Grid>
                    <Grid
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        <h2 style={{ fontWeight: "normal", color: "white" }}>{loadingText}</h2>
                    </Grid>
                </Grid>
            </Backdrop>
            {isConnected ? (
                <>
                    <Grid container justify="flex-start">
                        <Grid item>
                            <p style={{ color: "white", fontSize: 15 }}>Are you sure want to buy this NFT?</p>
                        </Grid>
                    </Grid>
                    <Grid container justify="flex-end" spacing={2}>
                        <Grid item>
                            <CustomTextButton
                                onClick={() => {
                                    dispatch(resetLoadingText());
                                    dispatch(closeModal());
                                }}
                                size="large"
                                type="submit"
                                variant="contained"
                                title="No"
                            />
                        </Grid>
                        <Grid item>
                            <CustomTextButton
                                onClick={handleBuyNow}
                                size="large"
                                type="submit"
                                variant="contained"
                                title="Yes"
                            />
                        </Grid>
                    </Grid>
                </>
            ) : (
                <>
                    <Grid container justify="flex-start">
                        <Grid item>
                            <p style={{ color: "white", fontSize: 15 }}>Connect your Phantom Wallet</p>
                        </Grid>
                    </Grid>
                    <Grid container justify="center" spacing={2}>
                        <Grid item>
                            <CustomTextButton
                                onClick={() => dispatch(handlePhantomConnectWithoutOpen())}
                                size="large"
                                type="submit"
                                variant="contained"
                                title="Connect"
                                style={{ width: "100%" }}
                            />
                        </Grid>
                    </Grid>
                </>
            )}
        </>
    );
};

export default BuyNowModalContent;
