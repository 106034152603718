import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Card, CardContent, Grid } from "@material-ui/core";

import componentStyles from "assets/theme/views/auth/login.js";
import { listAllSellingNfts, resetSellingNfts, resetLoadingText } from "store/actions";
import { convertToSOL } from "helpers";
import { history } from "utils";
import CustomTextButton from "components/CustomButton/CustomTextButton";

const useStyles = makeStyles(componentStyles);

function Home() {
    const classes = useStyles();
    const dispatch = useDispatch();

    const { sellingNfts, viewMoreButton } = useSelector(({ nfts: { list, showViewMore } }) => ({
        sellingNfts: list,
        viewMoreButton: showViewMore,
    }));

    useEffect(() => {
        dispatch(resetSellingNfts());
        dispatch(resetLoadingText());
        fetchMoreData();
    }, []);

    const fetchMoreData = () => {
        dispatch(listAllSellingNfts());
    };

    return (
        <>
            {sellingNfts.length ? (
                <Grid container justify="flex-start" spacing={3} style={{ padding: "1em" }}>
                    {sellingNfts.map((e, i) => {
                        return (
                            <Grid item xs={6} lg={3} md={7} key={i} style={{ display: "flex" }}>
                                <Card classes={{ root: classes.cardRoot }} style={{ width: "100%", height: "100%" }}>
                                    <CardContent>
                                        <Grid container justify="center">
                                            <Grid item>
                                                <h2 style={{ textTransform: "uppercase", fontWeight: "normal" }}>
                                                    {e?.nft_id?.name}
                                                </h2>
                                            </Grid>
                                        </Grid>
                                        <Grid container justify="center">
                                            <Grid item>
                                                <img width="150" height="150" src={e?.nft_id?.art_url} alt="" />
                                            </Grid>
                                        </Grid>
                                        <Grid container justify="center" style={{ paddingTop: "5px" }}>
                                            <Grid item>
                                                <h3>{convertToSOL(e?.amount)} SOL</h3>
                                            </Grid>
                                        </Grid>
                                        <Grid container justify="center">
                                            <Grid item>
                                                <CustomTextButton
                                                    title="View Details"
                                                    color="primary"
                                                    onClick={() => history.push(`/auth/buy-artwork/${e?.nft_id?.nft_id}`)}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </Grid>
                        );
                    })}
                </Grid>
            ) : (
                <Grid container justify="center" spacing={3} style={{ padding: "1em" }}>
                    <Grid>
                        <h2 style={{ color: "white", fontWeight: "normal" }}>No items available</h2>
                    </Grid>
                </Grid>
            )}
            {viewMoreButton ? (
                <Grid container justify="center" spacing={2} style={{ paddingTop: "10px" }}>
                    <Grid item>
                        <CustomTextButton title="Show More" />
                    </Grid>
                </Grid>
            ) : null}
        </>
    );
}

export default Home;
